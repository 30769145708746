<script setup lang="ts">
import UnsupportedDevice from '~/components/emptyStates/UnsupportedDevice.vue';
import { useMainStore } from '~/stores/mainStore';

const route = useRoute();
const store = useMainStore();

store.getSiteSettings();

const isMobileFriendly = computed(() => {
  return [...store.siteSettings.mobileFriendlyRoutes].includes(
    String(route.name),
  );
});
</script>

<template>
  <div>
    <UnsupportedDevice v-show="!isMobileFriendly" />

    <div class="flex h-screen flex-col" data-cy="blank">
      <slot></slot>
    </div>
  </div>
</template>
